<template>
  <router-view />
</template>

<script>
export default {
  name: "homogeneous",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
